@import "~bootswatch/dist/pulse/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/pulse/bootswatch";

$navbar-height: 53px;

$navbar-background: theme-color('primary');
.navbar {
    @extend .navbar-dark;
    background-color: $navbar-background;
}

.list-group {
    background-color: $list-group-bg;
}

@import '../main.scss';
